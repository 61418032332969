import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatesRoutingModule } from './templates-routing.module';

import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TemplatesComponent } from './components/templates/templates.component';

@NgModule({
  declarations: [TemplatesComponent],
  imports: [TemplatesRoutingModule, SharedModule, ReactiveFormsModule, CommonModule],
})
export class TemplatesModule {}
